import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import '../styles/layout.css';
import UkrainianPhoneInput from "./component/UkrainianPhoneInput";

export const PhoneNumberPage = ({toResults}) => {
    const [phoneNumber, setPhoneNumber] = React.useState('');

    return <Container fluid={true} className="fade-in">
        <Row style={{paddingTop: '25%', paddingLeft: '3%', paddingRight: '3%'}} className="justify-content-center">
            <Col>
                    <span
                        style={{
                            fontFamily: 'Architects Daughter',
                            fontSize: 29,
                            textAlign: 'center'
                        }}
                    >
                    Для зворотнього зв'язку
                </span>
            </Col>
        </Row>
        <Row className="justify-content-center custom-row" style={{paddingTop: '10%', fontFamily: 'Architects Daughter',}}>
            <Col md={10}>
                <UkrainianPhoneInput onHaveError={() => {}} updatePhoneNumber={setPhoneNumber} phoneOld={phoneNumber} />
            </Col>
        </Row>
        <Row className="justify-content-center custom-row" style={{paddingTop: '10%', fontFamily: 'Architects Daughter'}}>
            <Col>
                <Button onClick={() => toResults(phoneNumber)}
                        className={`btn btn-primary ${phoneNumber == '' ? '' : 'shadow'}`}
                        disabled={phoneNumber == ''}
                        style={{
                            fontFamily: "Architects Daughter",
                            backgroundColor: phoneNumber == '' ? '#666' : 'black',
                            fontSize: 22,
                            border: phoneNumber == '' ? '':'2px solid black',
                            color: 'white',
                            padding: '10px',
                            borderRadius: '10px',
                            marginTop: '10%'
                        }}>
                    РЕЗУЛЬТАТИ📊
                </Button>
            </Col>
        </Row>
    </Container>
}