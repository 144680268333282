import './App.css';
import {MainPage} from "./layout/MainPage";
import {useEffect, useState} from "react";
import {ContactPage} from "./layout/ContactPage";
import {QuestionPage} from "./layout/QuestionPage";
import {PhoneNumberPage} from "./layout/PhoneNumberPage";
import {ResultsPage} from "./layout/ResultsPage";

let globalActiveTab = 0; // Глобальная переменная для отслеживания активной вкладки
let globalCurrentQuestion = 0;

function App() {
    const [activeTab, changeTab] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [skills, setSkills] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [secondPhoneNumber, setSecondPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');

    useEffect(() => {
        globalActiveTab = activeTab
    }, [activeTab]);

    useEffect(() => {
        globalCurrentQuestion = currentQuestion
    }, [currentQuestion]);

    useEffect(() => {
        const handlePageHide = () => {
            const exit = determineExitPage();
            navigator.sendBeacon('https://tmarxeb4e3.execute-api.us-east-1.amazonaws.com/exit-logger', JSON.stringify({ exit }));
        };

        window.addEventListener('pagehide', handlePageHide);
        window.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('pagehide', handlePageHide);
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            const exit = determineExitPage();
            navigator.sendBeacon('https://tmarxeb4e3.execute-api.us-east-1.amazonaws.com/exit-logger', JSON.stringify({ exit }));
        }
    };


    const determineExitPage = () => {
        switch (globalActiveTab) {
            case 1:
                return "Contacts page";
            case 2:
                return `Question #${globalCurrentQuestion}`;
            case 3:
                return "Phone Number page";
            case 4:
                return "Results page";
            default:
                return "Main page";
        }
    };


    useEffect(() => {
        setSecondPhoneNumber(phoneNumber);
    }, [phoneNumber])

    useEffect(() => {
        if (activeTab == 4) {
            fetch('https://tmarxeb4e3.execute-api.us-east-1.amazonaws.com/womanizer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name, age, phone_number: phoneNumber, results: skills, second_phone: secondPhoneNumber, is_request: false, name_channel: 'default', chose: 'default'})
            })
                .then(response => response.json())
                .then(data => console.log(data))
                .catch(error => console.error('Error:', error));
        }
    }, [activeTab]);


    const onSendRequest = (name_c, chose) => {
            fetch('https://tmarxeb4e3.execute-api.us-east-1.amazonaws.com/womanizer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name, age, phone_number: phoneNumber, results: skills, second_phone: secondPhoneNumber, is_request: true, name_channel: name_c, chose: chose})
            })
                .then(response => response.json())
                .then(data => console.log(data))
                .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
        const skills = [
            { id: 0, name: 'Творчість🎨', score: 0 },
            { id: 1, name: 'Логіка🕵️', score: 0 },
            { id: 2, name: 'Активність🤸', score: 0 },
            { id: 3, name: 'Стресостійкість🧘‍♂️', score: 0 },
            { id: 5, name: 'Увага🔎', score: 0 },
        ]

        const questions = [
            {
                index: 1,
                question: 'Яка діяльність є більш цікавою для вашої дитини?',
                options:[
                    {name: 'Малювання', skill_id: 0, score: 2},
                    {name: 'Комп\'ютерні ігри', skill_id: 1, score: 1},
                    {name: 'Математика', skill_id: 1, score: 3},
                    {name: 'Настільні ігри', skill_id: 1, score: 2}
                ]
            },
            {
                index: 2,
                question: 'Що найкраще описує вашу дитину?',
                options: [
                    {name: 'Легко зацікавлюється, але швидко "перегорає"', skill_id: 2, score: 2},
                    {name: 'Має улюблену справу та займається тільки нею', skill_id: 1, score: 3},
                    {name: 'Важко знаходить те, що подобається', skill_id: 2, score: -1}
                ]
            },
            {
                index: 3,
                question: 'Коли дитина розповідає вам щось, вона більше уваги приділяє:',
                options: [
                    {name: 'Емоціям і переживанням', skill_id: 0, score: 2},
                    {name: 'Фактам', skill_id: 3, score: 2},
                    {name: '50 на 50', skill_id: 1, score: 1}
                ]
            },
            {
                index: 4,
                question: 'Коли дитина стикається з новим завданням, вона:',
                options: [
                    {name: 'Відразу береться за діло', skill_id: 2, score: 2},
                    {name: 'Продумує план', skill_id: 1, score: 3},
                    {name: 'Звертається за порадою', skill_id: 2, score: 1}
                ]
            },
            {
                index: 5,
                question: 'Приступаючи до роботи, дитина частіше:',
                options: [
                    {name: 'Слідкує за інструкцією', skill_id: 4, score: 3},
                    {name: 'Шукає свій підхід', skill_id: 0, score: 2},
                    {name: 'Не впевнений(-на)', skill_id: 3, score: -1}
                ]
            },
            {
                index: 6,
                question: 'Як ваша дитина реагує на несподівані зміни планів?',
                options: [
                    {name: 'Легко адаптується', skill_id: 3, score: 2},
                    {name: 'Відчуває стрес', skill_id: 3, score: -2},
                    {name: 'Намагається контролювати ситуацію', skill_id: 1, score: 1}
                ]
            },
            {
                index: 7,
                question: 'Як ваша дитина ставиться до виконання детальних інструкцій?',
                options: [
                    {name: 'Дотримується строго інструкцій', skill_id: 4, score: 3},
                    {name: 'Намагається змінити інструкції під себе', skill_id: 0, score: 2},
                    {name: 'Не любить виконувати детальні інструкції', skill_id: 2, score: -1}
                ]
            },
            {
                index: 8,
                question: 'Як ваша дитина вирішує проблеми, коли вони виникають?',
                options: [
                    {name: 'Аналізує проблему і шукає логічне рішення', skill_id: 1, score: 3},
                    {name: 'Прохає допомоги у друзів або батьків', skill_id: 0, score: 2},
                    {name: 'Вирішує методом проб і помилок', skill_id: 1, score: 1}
                ]
            },
            {
                index: 9,
                question: 'Як ваша дитина відноситься до групових проектів?',
                options: [
                    {name: 'Любить бути лідером і координувати діяльність групи', skill_id: 2, score: 3},
                    {name: 'Віддає перевагу виконувати завдання індивідуально', skill_id: 4, score: 2},
                    {name: 'Активний учасник, але не лідер', skill_id: 2, score: 1}
                ]
            }
        ];

        setSkills(skills);
        setQuestions(questions);
    }, []);

    function nextTab() {
        changeTab(prevTab => prevTab + 1);
    }

    function onNextQuestion(answer_id) {
        if (answer_id) {
            const skill_id = questions[currentQuestion].options[answer_id].skill_id;
            const score = questions[currentQuestion].options[answer_id].score;

            const skills_new = skills;
            skills_new[skill_id].score = skills_new[skill_id].score + score;

            setSkills(skills_new);
        }

        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(prev => prev + 1);
        } else {
            nextTab();
        }
    }

    return (
        <div className="App">
            {activeTab === 0 && <MainPage nextPage={() => changeTab(1)}/> ||
                activeTab === 1 && <ContactPage nextPage={({name, age}) => {setName(name); setAge(age);changeTab(2)}}/> ||
                activeTab === 2 && <QuestionPage
                    key={currentQuestion}
                    question={questions[currentQuestion].question}
                    options={questions[currentQuestion].options}
                    onNextQuestion={(answer_id) => {onNextQuestion(answer_id)}}
                    index={currentQuestion}
                    count={questions.length}
                /> ||
                activeTab === 3 && <PhoneNumberPage toResults={(phone) => {setPhoneNumber(phone); changeTab(4)}}/> ||
                activeTab === 4 && <ResultsPage phoneNumber={secondPhoneNumber} onUpdatePhoneNumber={(phone) => {setSecondPhoneNumber(phone)}} skills={skills} onSendRequest={onSendRequest}/>
            }
        </div>
    );
}

export default App;
