import '../styles/layout.css';
import React from 'react';
import { Button, Col, Container, Row } from "react-bootstrap";
import {InstagramIcon} from "./component/icon/InstagramIcon";

export const MainPage = ({nextPage}) => {

    const openInstagram = () => {
        fetch('https://tmarxeb4e3.execute-api.us-east-1.amazonaws.com/exit-logger', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'exit':'Open Instagram'})
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));

        // Ссылка, которая открывает профиль в приложении Instagram
        const appLink = `instagram://user?username=real_itschool`;
        // Ссылка, которая открывает профиль в браузере
        const webLink = `https://www.instagram.com/real_itschool/`;

        // Пытаемся открыть приложение
        window.open(appLink, '_blank');

        // Проверка, открылось ли приложение, если нет - открыть в браузере (необязательно)
                window.open(webLink, '_blank');
    }

    return (
        <Container fluid={true} className="fade-in">
            <Row className="main-content">
                <Row className={"justify-content-md-center"}>
                    <Col>
                        <span style={{textAlign: 'left', fontSize: 29, textWrap: 'pretty', fontFamily: 'Architects Daughter'}}>Дізнайтесь який напрям IT найкраще підходить вашій дитині!</span>
                    </Col>
                </Row>
                <Row className={"justify-content-md-center"} style={{ paddingTop: '9%' }}>
                    <Col>
                        <Button onClick={nextPage} className='btn btn-primary shadow' style={{
                            fontFamily: "Architects Daughter",
                            backgroundColor: 'black',
                            fontSize: 22,
                            color: 'white',
                            padding: '10px',
                            borderRadius: '10px'
                        }}>
                            ПОЇХАЛИ 🚀
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{paddingTop: '2%'}}>
                        <Button onClick={openInstagram} className='btn btn-primary shadow' style={{
                            fontFamily: "Architects Daughter",
                            backgroundColor: 'white',
                            fontSize: 13,
                            color: 'black',
                            padding: '10px',
                            marginTop: '1%',
                            marginBottom: '2%',
                            borderRadius: '10px'
                        }}>
                            <div style={{display: "flex"}}>
                                НАШ ІНСТАГРАМ <InstagramIcon
                                style={{marginLeft: '10px', verticalAlign: 'middle', backgroundColor: 'red'}}/>
                            </div>
                        </Button>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
}
