import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import '../styles/layout.css'

export const ContactPage = ({nextPage}) => {
    const [age, setAge] = React.useState('');
    const [name, setName] = React.useState('');

    return <Container fluid={true} className="fade-in" >
        <Row className="justify-content-center"
             style={{padding: '2%', marginLeft: '3%', marginRight: '3%'}}>
            <Col>
                <span
                    style={{
                        fontFamily: 'Architects Daughter',
                        fontSize: 27,
                        textAlign: 'center'
                    }}
                >
                    Давайте познайомимося 👋
                </span>
            </Col>
        </Row>
        <Row className="justify-content-center custom-row" style={{marginTop: '10%', fontFamily: 'Architects Daughter',}}>
            <Col md={10}>
                <input onChange={(e) => setName(e.target.value)} type='text' className='custom-input' placeholder="Ваше ім'я" />
                <input onChange={(e) => setAge(e.target.value)} type='number' className='custom-input' placeholder="Вік дитини"/>
            </Col>
        </Row>
        <Row className="justify-content-center custom-row" style={{marginTop: '10%', fontFamily: 'Architects Daughter'}}>
            <Col>
                <Button onClick={() => nextPage({name, age})}
                        className={`btn btn-primary ${name == '' || age == '' ? '' : 'shadow'}`}
                        disabled={name == '' || age == ''}
                        style={{
                            fontFamily: "Architects Daughter",
                            backgroundColor: name == '' || age == '' ? '#666' : 'black',
                            fontSize: 22,
                            border: name == '' || age == '' ? '':'2px solid black',
                            color: 'white',
                            padding: '10px',
                            marginTop: '10%',
                            borderRadius: '10px'
                        }}>
                    ДО ТЕСТУ📊
                </Button>
            </Col>
        </Row>
    </Container>
}
