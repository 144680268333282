import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer } from 'recharts';

function MarketingRadarChart({ data }) {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart outerRadius={80} data={data}>
                <PolarGrid />
                <PolarAngleAxis
                    tick={{
                        fill: 'black',
                        fontFamily: 'Architects Daughter',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                    dataKey="category"
                />
                <Radar
                    name="Сотрудник"
                    dataKey="value"
                    stroke="#e36355"
                    fill="#e36355"
                    fillOpacity={0.8}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
}


export default MarketingRadarChart;
