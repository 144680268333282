import React, {useEffect, useState} from 'react';
import '../../styles/layout.css'

const UkrainianPhoneInput = ({ updatePhoneNumber, phoneOld, onHaveError }) => {
    const [error, setError] = useState('');
    const [phone, setPhone] = useState(phoneOld == '' ? '+380 (':phoneOld);
    useEffect(() => {
        if (phone) {
            const cleanInput = phone.replace(/^\+380 \(|[^\d]/g, '');
            const formattedPhone = formatPhone(cleanInput);
            onHaveError(!validatePhone(formattedPhone))
        }
    }, [phone]);

    const formatPhone = (input) => {
        const numbers = input.replace(/\D/g, '');
        let phone1 = '+380 (';

        if (numbers.length > 0) {
            phone1 += numbers.slice(0, 2);
        }
        if (numbers.length > 2) {
            phone1 += `) ${numbers.slice(2, 5)}`;
        }
        if (numbers.length > 5) {
            phone1 += `-${numbers.slice(5, 7)}`;
        }
        if (numbers.length > 7) {
            phone1 += `-${numbers.slice(7, numbers.length)}`;
        }

        return phone1;
    };

    const validatePhone = (phone) => {
        return phone.length == 19 || phone.length == 20
    };

    const handleChange = (e) => {
        const input = e.target.value;
        // Убираем дублирование префикса
        const cleanInput = input.replace(/^\+380 \(|[^\d]/g, '');
        const formattedPhone = formatPhone(cleanInput);
        setPhone(formattedPhone);
        onHaveError(!validatePhone(formattedPhone))
        if (!validatePhone(formattedPhone)) {
            setError('Формат: +380 (XX) XXX-XX-XX');
        } else {
            setError('');
            console.log("update-phone")
            updatePhoneNumber(formattedPhone);
        }
    };

    return (
        <div>
            <input
                type="text"
                value={phone}
                onChange={handleChange}
                placeholder="+380 (XX) XXX-XX-XX"
                className="custom-input"
            />
            {error && <div style={{ color: 'red', fontSize: 13, paddingTop: '2%' }}>{error}</div>}
        </div>
    );
};


export default UkrainianPhoneInput;
