import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import GradientCircularProgress from "./component/GradientCircularProgress";
import MarketingRadarChart from "./component/MarketingRadarChart";
import '../styles/layout.css'
import {InstagramIcon} from "./component/icon/InstagramIcon";
import {TelegramIcon} from "./component/icon/TelegramIcon";
import {ViberIcon} from "./component/icon/ViberIcon";
import {FeedbackModal} from "./component/FeedbackModal";

export const ResultsPage = ({skills, onSendRequest, phoneNumber, onUpdatePhoneNumber}) => {
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000);
        const data = [];
        const minScore = Math.min(...skills.map(skill => skill.score));
        const shift = minScore <= 0 ? 3 - minScore : 0;

        skills.forEach(skill => {
            const d = {
                category: skill.name,
                value: skill.score + shift
            }
            data.push(d);
        });
        setChartData(data);
    }, []);

    function displayResults(skills) {
        skills.sort((a, b) => b.score - a.score);
        const topSkills = skills.slice(0, 3);

        let frontendScore = 0;
        let backendScore = 0;
        const skillDescriptions = topSkills.map(skill => {
            let text;
            switch (skill.name) {
                case 'Творчість🎨':
                    text = 'Творчість ідеально підходить для Frontend розробки. Розвивайте здатність придумувати оригінальні інтерфейси та взаємодії! Унікальні дизайни та інноваційні рішення дозволять створювати захоплюючі і корисні продукти для користувачів.';
                    frontendScore++;
                    break;
                case 'Логіка🕵️':
                    text = 'Сильна логіка є важливою як для Frontend, так і для Backend. Ваша дитина може стати відмінним розробником в будь-якій із цих галузей! Розвиток логічного мислення допоможе легше вирішувати програмні та алгоритмічні задачі.';
                    frontendScore++;
                    backendScore++;
                    break;
                case 'Активність🤸':
                    text = 'Активність допоможе швидко адаптуватися до змін у проектах, що особливо цінно в Frontend розробці. Бути завжди в курсі нових технологій і трендів в дизайні та розробці допоможе завжди бути на крок попереду.';
                    frontendScore++;
                    break;
                case 'Стресостійкість🧘‍♂️':
                    text = 'Стресостійкість допоможе у роботі з Backend, де потрібно вирішувати складні задачі під тиском. Здатність зберігати спокій під час критичних ситуацій забезпечить надійність та ефективність роботи серверних рішень.';
                    backendScore++;
                    break;
                case 'Увага🔎':
                    text = 'Здатність звертати увагу на деталі зробить дитину відмінним Frontend розробником. Досконале знання і вміння відслідковувати найменші нюанси в дизайні та інтерфейсах значно підвищить якість кінцевих продуктів.';
                    frontendScore++;
                    break;
            }
            return (
                <>
                    <h4 style={{
                        margin: '0',
                        paddingTop: '0',
                        fontFamily: 'Architects Daughter',
                        fontSize: 'larger'
                    }}>{skill.name}</h4>
                    <p style={{
                        whiteSpace: "pre-wrap",
                        marginTop: '15px',
                        marginBottom: '25px',
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        fontFamily: 'Architects Daughter'
                    }}>{text}</p>
                </>
            );
        });

        const names = topSkills.map(skill => skill.name);
        const textSkills = (
            <>
                <p style={{
                    textAlign: 'left',
                    paddingLeft: '20px',
                    paddingBottom: '0',
                    marginBottom: '0',
                    fontWeight: 'normal'
                }}>З урахуванням топ навичок:</p>
                <ul style={{
                    listStyleType: 'disc', // черные точки для элементов списка
                    textAlign: 'left', // выравнивание текста по левому краю
                    paddingLeft: '20px',
                    paddingTop: '0',
                    marginTop: '5px'// отступ для красивого выравнивания
                }}>
                    {topSkills.map((skill, index) => (
                        <li key={index} style={{
                            textAlign: 'left', fontWeight: 'normal' // текст элементов списка по левому краю
                        }}>
                            {skill.name}
                        </li>
                    ))}
                </ul>
            </>
        );

        const finalRecommendation = frontendScore > backendScore ?
            "З урахування ваших результатів Frontend напрям може бути найкращим вибіром!" : "З урахування ваших результатів Backend напрям може бути найкращим вибіром!"

        return (
            <>
                {skillDescriptions}
                <>
                    <h4 style={{
                        margin: '0',
                        paddingTop: '0',
                        fontFamily: 'Architects Daughter',
                        fontSize: 'larger',
                        fontWeight: 'bold'
                    }}>Результат📊</h4>
                    <p style={{
                        whiteSpace: "pre-wrap",
                        marginTop: '15px',
                        fontSize: 'large',
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        fontFamily: 'Architects Daughter',
                        fontWeight: 'bold'
                    }}>{finalRecommendation}</p>
                </>
            </>
        );
    }

    const openInstagram = () => {
        // Ссылка, которая открывает профиль в приложении Instagram
        const appLink = `instagram://user?username=real_itschool`;
        // Ссылка, которая открывает профиль в браузере
        const webLink = `https://www.instagram.com/real_itschool/`;

        // Пытаемся открыть приложение
        window.open(appLink, '_blank');

        // Проверка, открылось ли приложение, если нет - открыть в браузере (необязательно)
        const startTime = Date.now();
        setTimeout(() => {
            if (Date.now() - startTime < 1500) {
                window.open(webLink, '_blank');
            }
        }, 1000);
    }

    console.log(chartData)
    return (
        <>
            {loading ? (
                <Container fluid={true} style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh"
                }}>
                    <GradientCircularProgress/>
                </Container>
            ) : (
                <Container fluid={true} className="fade-in">
                    <Row style={{paddingTop: "2%", paddingLeft: "3%", paddingRight: "3%"}}>
                        <Col style={{fontFamily: "Architects Daughter"}}>
                            <MarketingRadarChart data={chartData}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {displayResults(skills)}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{paddingRight: '2%', paddingLeft: '2%'}}>
                            <Button onClick={() => {
                                setIsOpen(true)
                            }}
                                    className='btn btn-primary shadow' style={{
                                fontFamily: "Architects Daughter",
                                backgroundColor: 'white',
                                fontSize: 'large',
                                borderRadius: '10px',
                                color: 'black',
                                padding: '10px',
                            }}>
                                <div style={{display: 'flex'}}>
                                    КОНСУЛЬТАЦІЯ З НАПРЯМУ <TelegramIcon
                                    style={{marginLeft: '10px', verticalAlign: 'middle'}}/><ViberIcon
                                    style={{marginLeft: '10px', verticalAlign: 'middle'}}/>
                                </div>
                            </Button>
                        </Col>
                        <Col style={{paddingTop: '2%'}}>
                            <Button onClick={openInstagram} className='btn btn-primary shadow' style={{
                                fontFamily: "Architects Daughter",
                                backgroundColor: 'white',
                                fontSize: 13,
                                color: 'black',
                                padding: '10px',
                                marginTop: '1%',
                                marginBottom: '2%',
                                borderRadius: '10px'
                            }}>
                                <div style={{display: "flex"}}>
                                    НАШ ІНСТАГРАМ <InstagramIcon
                                    style={{marginLeft: '10px', verticalAlign: 'middle', backgroundColor: 'red'}}/>
                                </div>
                            </Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            )}
            <FeedbackModal isOpen={isOpen} onUpdateNumber={onUpdatePhoneNumber} number={phoneNumber}
                           handleClose={() => setIsOpen(false)} onSendRequest={onSendRequest}/>
        </>
    );
}
