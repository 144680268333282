import React, {useEffect} from 'react';
import {Box, Modal, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {TelegramIcon} from "./icon/TelegramIcon";
import {ViberIcon} from "./icon/ViberIcon";
import {Button, Col, Row} from "react-bootstrap";
import '../../styles/layout.css'
import {Label} from "recharts";
import UkrainianPhoneInput from "./UkrainianPhoneInput";

const confirmStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '3px solid black',
    boxShadow: 24,
    borderRadius: 15,
    p: 4,
};

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid black',
    boxShadow: 24,
    borderRadius: 15,
    p: 4,
};

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const FeedbackModal = ({isOpen, handleClose, number, onSendRequest, onUpdateNumber}) => {
    const [value, setValue] = React.useState(0);
    const [telegram, setTelegram] = React.useState('');
    const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    console.log("Feedback phone " + number)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleConfirmModal = (chose) => {
        setIsConfirmOpen(prev => !prev);
        if (chose == 'viber' || chose == 'telegram' || chose == 'phone') {
            onSendRequest(telegram, chose);
        }
        handleClose();
    }

    return <> <Modal
        className = 'fade-in'
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Row className="justify-content-center">
                <Col>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <span style={{
                            fontFamily: 'Architects Daughter',
                            fontSize: 25,
                            padding: 0,
                            marginTop: 0,
                            marginBottom: 10,
                            alignSelf: "center"
                        }}>Як з вами зв'язатися?</span>
                    </div>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label={<span style={{fontSize: 22}}>📲</span>}/>
                        <Tab label={<TelegramIcon
                            style={{marginLeft: '10px', verticalAlign: 'middle'}}/>}/>
                        <Tab label={<ViberIcon
                            style={{marginLeft: '10px', verticalAlign: 'middle'}}/>}/>
                    </Tabs>
                </Col>
            </Row>
            <CustomTabPanel value={value} index={0}>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <span style={{fontSize: 22, fontFamily: 'Architects Daughter'}}>Перевірте корректність номеру</span>
                        <UkrainianPhoneInput onHaveError={setHasError} phoneOld={number} updatePhoneNumber={onUpdateNumber} />
                    </Col>
                    <Col md={10}>
                    <Button onClick={() => toggleConfirmModal('phone')}
                            className={`btn btn-primary ${hasError ? '' : 'shadow'}`}
                            disabled={hasError}
                            style={{
                                fontFamily: "Architects Daughter",
                                backgroundColor: hasError ? '#666' : 'black',
                                fontSize: 22,
                                color: 'white',
                                padding: '10px',
                                display: 'block',
                                margin: '12% auto',
                                marginBottom: '0%',
                                borderRadius: '10px'
                            }}>
                        ВІДПРАВИТИ✈️
                    </Button>
                    </Col>
                </Row>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <span style={{
                            fontSize: 22,
                            fontFamily: 'Architects Daughter'
                        }}>Перевірте корректність данних</span>
                        <UkrainianPhoneInput onHaveError={setHasError} phoneOld={number} updatePhoneNumber={onUpdateNumber} />
                        <input type='text' value={telegram} onChange={(e) => setTelegram(e.target.value)} className='custom-input' placeholder="Нікнейм, якщо є (починається з @)"/>
                    </Col>
                    <Col md={10}>
                        <Button onClick={() => toggleConfirmModal('telegram')}
                                className={`btn btn-primary ${hasError ? '' : 'shadow'}`}
                                disabled={hasError}
                                style={{
                                    fontFamily: "Architects Daughter",
                                    backgroundColor: hasError ? '#666' : 'black',
                                    fontSize: 22,
                                    color: 'white',
                                    padding: '10px',
                                    display: 'block',
                                    margin: '12% auto',
                                    marginBottom: '0%',
                                    borderRadius: '10px'
                                }}>
                            ВІДПРАВИТИ✈️
                        </Button>
                    </Col>
                </Row>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <span style={{
                            fontSize: 22,
                            fontFamily: 'Architects Daughter'
                        }}>Перевірте корректність номеру</span>
                        <UkrainianPhoneInput onHaveError={setHasError} phoneOld={number} updatePhoneNumber={onUpdateNumber} />
                    </Col>
                    <Col md={10}>
                        <Button onClick={() => toggleConfirmModal('viber')}
                                className={`btn btn-primary ${hasError ? '' : 'shadow'}`}
                                disabled={hasError}
                                style={{
                                    fontFamily: "Architects Daughter",
                                    backgroundColor: hasError ? '#666' : 'black',
                                    fontSize: 22,
                                    color: 'white',
                                    padding: '10px',
                                    display: 'block',
                                    margin: '12% auto',
                                    marginBottom: '0%',
                                    borderRadius: '10px'
                                }}>
                            ВІДПРАВИТИ✈️
                        </Button>
                    </Col>
                </Row>
            </CustomTabPanel>
        </Box>
    </Modal>
        <Modal
            open={isConfirmOpen}
            onClose={toggleConfirmModal}
        >
            <Box sx={style}>
                <span style={{fontSize: 22,
                    fontFamily: 'Architects Daughter',
                    textAlign: "center"}}>
                    Дякуємо за звернення! <br/>
                    Ми зв'яжемося з вами найближчим часом!
                </span>
            </Box>
        </Modal>
    </>
}