import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import '../styles/layout.css';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import StepsProgressBar from "./component/StepProgressBar"; // Убедитесь, что CSS файл правильно подключен

export const QuestionPage = ({question, options, onNextQuestion, index, count}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [progress, setProgress] = useState([]);

    useEffect(() => {
        let prgs = [];
        for (let i = 1; i <= count; i++) {
            prgs.push({
                text: i.toString(),
                isActive: i <= index + 1
            });
        }
        setProgress(prgs);
    }, [index]);

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleNextQuestion = () => {
        onNextQuestion(selectedOption);
    };

    return (
        <Container fluid={true} className="fade-in" style={{fontFamily: 'Architects Daughter'}}>
            <Row>
                <Col style={{paddingTop: '10%', paddingLeft: '7%', paddingRight: '7%'}}>
                    <StepsProgressBar steps={progress}/>
                </Col>
            </Row>
            <Row className="justify-content-center"
                 style={{paddingTop: '13%', paddingLeft: '5%', paddingRight: '5%'}}>
                <Col>
                    <span style={{fontSize: 29}}>{question}</span>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col style={{padding: '15%'}}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={handleRadioChange}
                        >
                            {options.length > 0 && options.map((option, index) => {
                                return <FormControlLabel
                                    key={index}
                                    value={index.toString()}
                                    control={<Radio sx={{
                                        '& .MuiSvgIcon-root': {fontSize: 32},
                                        color: 'black',
                                        '&.Mui-checked': {color: 'black'},
                                    }}/>}
                                    style={{textAlign: 'left', textWrap: 'pretty', padding: '3%', fontFamily: 'Architects Daughter'}}
                                    label={option.name}
                                />
                            })}
                        </RadioGroup>
                    </FormControl>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <Button onClick={handleNextQuestion}
                            className={`btn btn-primary ${selectedOption === '' ? '' : 'shadow'}`}
                            disabled={selectedOption === ''}
                            style={{
                                fontFamily: "Architects Daughter",
                                backgroundColor: selectedOption === '' ? '#666' : 'black',
                                fontSize: 22,
                                color: 'white',
                                padding: '10px',
                                marginTop: '10%',
                                borderRadius: '10px'
                            }}>
                        НАСТУПНЕ ПИТАННЯ✈️
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}



