import React from 'react';
import './StepProgressBar.css';

const Step = ({ text, isActive }) => {
    return (
        <div className={`step ${isActive ? 'active' : ''}`}>
            {text}
        </div>
    );
};


const StepsProgressBar = ({ steps }) => {
    const lastActiveIndex = steps.map(step => step.isActive).lastIndexOf(true);

    return (
        <div className="stepsProgressBar">
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <Step text={step.text} isActive={step.isActive} />
                    {index < steps.length - 1 && (
                        <div className={`line ${index <= lastActiveIndex ? 'active' : ''} ${index === lastActiveIndex ? 'partial' : ''}`}>
                            {index === lastActiveIndex && <div className="partialFill" />}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default StepsProgressBar;
